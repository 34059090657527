import { ExclamationIcon, TrashIcon } from '@heroicons/react/outline'
import { Row } from '@tanstack/react-table'
import { ShortOrder } from '../../../../services/api/order/OrderService.types'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import Button from '../../../base/button/Button'
import ConfirmDialog from '../../../base/confirm-dialog/ConfirmDialog'
import Tooltip from '../../../base/tooltip/Tooltip'
import useOrderDelete from '../../../../hooks/api/order/useOrderDelete'
import useToast from '../../../../hooks/useToast'

type DemandDeleteButtonProps = {
    row: Row<ShortOrder>
    canDelete?: boolean
}

export const DemandTableActionCellDeleteButton = ({ row, canDelete }: DemandDeleteButtonProps) => {
    const { t } = useTranslation('common')
    const toast = useToast()
    const router = useRouter()
    const { mutate: deleteOrder, isLoading: isDeletingOrder } = useOrderDelete()

    return canDelete ? (
        <ConfirmDialog
            text={t('frontend.demand.delete.confirm_dialog')}
            okLabel={t('frontend.demand.delete.confirm_dialog.confirm_button')}
            onConfirm={() => {
                deleteOrder(row.original.id, {
                    onSuccess: () => {
                        toast.success(t('frontend.dashboard.demand.table.action.delete.success_toast'))
                    }
                })
            }}
        >
            {open => (
                <Tooltip content={t('frontend.dashboard.demand.table.action.delete')}>
                    <Button icon styleType='text' loading={isDeletingOrder} onClick={open}>
                        <TrashIcon />
                    </Button>
                </Tooltip>
            )}
        </ConfirmDialog>
    ) : (
        <ConfirmDialog
            icon={<ExclamationIcon />}
            text={t('frontend.demand.delete.confirm_dialog.can_not_delete')}
            okLabel={t('frontend.demand.delete.confirm_dialog.can_not_delete.redirect_button', {
                number: row.original.priceOffers[0].number
            })}
            onConfirm={() => {
                router.push(`/app/price-offers/create/${row.original.priceOffers[0].id}`)
            }}
        >
            {open => (
                <Tooltip content={t('frontend.dashboard.demand.table.action.delete')}>
                    <Button icon styleType='text' onClick={open}>
                        <TrashIcon />
                    </Button>
                </Tooltip>
            )}
        </ConfirmDialog>
    )
}
