import { DemandTableActionCellDeleteButton } from './DemandTableActionCellDeleteButton'
import { DocumentDuplicateIcon, PencilIcon } from '@heroicons/react/outline'
import { Row } from '@tanstack/react-table'
import { ShortOrder } from '../../../../services/api/order/OrderService.types'
import { buildClasses } from '../../../../utils/StyleHelper'
import { filterNoGhostPriceOffers } from '../../../../utils/Helper'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import Button from '../../../base/button/Button'
import InvoiceIcon from '../../../base/icon/InvoiceIcon'
import Link from 'next/link'
import React, { useMemo } from 'react'
import Tooltip from '../../../base/tooltip/Tooltip'
import useOrderCopy from '../../../../hooks/api/order/useOrderCopy'
import useTheme from '../../../../hooks/useTheme'

export type DemandTableActionCellProps = {
    row: Row<ShortOrder>
}

const DemandTableActionCell = ({ row }: DemandTableActionCellProps) => {
    const { t } = useTranslation('common')
    const { mutate: copyOrder, isLoading: isCopyingOrder } = useOrderCopy()
    const { secondaryColor } = useTheme()

    const isDemandWriteable = row.original.status === 'I'
    const router = useRouter()

    const buttonClasses = buildClasses({
        'w-16': true
    })

    const demandEditLink = useMemo<string>(() => {
        return `/app/demand/create/${row.original.id}?edit=true&step=${row.original?.expedition?.length > 0 ? 3 : 2}`
    }, [row.original?.expedition?.length, row.original.id])

    const filteredNoGhostPriceOffers = useMemo(
        () => filterNoGhostPriceOffers(row.original.priceOffers || []),
        [row.original.priceOffers]
    )

    const isDemandInPriceOffer = !!filteredNoGhostPriceOffers.length

    const redirectToPriceOfferDetail = (id: number) => {
        return router.push(`/app/price-offers/create/${id}`)
    }

    return (
        <div className='flex items-center gap-2.5'>
            {isDemandWriteable ? (
                <Button as={Link} href={demandEditLink} size='tiny' className={buttonClasses}>
                    {t('frontend.dashboard.demand.table.action.edit.force')}
                </Button>
            ) : (
                <Button as={Link} href={`/app/demand/${row.original.id}`} size='tiny' className={buttonClasses}>
                    {t('frontend.dashboard.demand.table.action.detail')}
                </Button>
            )}

            {!isDemandWriteable && (
                <Tooltip content={t('frontend.dashboard.demand.table.action.edit')}>
                    <Button icon styleType='text' variant='secondary' as={Link} href={demandEditLink}>
                        <PencilIcon />
                    </Button>
                </Tooltip>
            )}
            {isDemandInPriceOffer && !isDemandWriteable && (
                <Tooltip content={t('frontend.dashboard.demand.table.action.detail_price_offer')}>
                    <Button
                        icon
                        styleType='text'
                        variant='secondary'
                        onClick={() => {
                            const firstPriceOffer = filteredNoGhostPriceOffers[0]
                            return redirectToPriceOfferDetail(firstPriceOffer.id)
                        }}
                    >
                        <InvoiceIcon fill={secondaryColor} />
                    </Button>
                </Tooltip>
            )}
            <Tooltip content={t('frontend.dashboard.demand.table.action.copy')}>
                <Button
                    icon
                    variant='secondary'
                    styleType='text'
                    loading={isCopyingOrder}
                    onClick={() => copyOrder({ orderIds: [row.original.id] })}
                >
                    <DocumentDuplicateIcon />
                </Button>
            </Tooltip>
            <DemandTableActionCellDeleteButton row={row} canDelete={!row.original.priceOffers?.length} />
        </div>
    )
}

export default DemandTableActionCell
